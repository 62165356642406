const $ = require("jquery");

$(function traducaoHome (){
  
  const idiomaAtual = localStorage.getItem("idioma");
   
    $(`#${idiomaAtual}`).css("color", "#22845c");
  
    $(".tipoIdioma").click(function() {
      const idioma = $(this).attr("id");

      


        localStorage.setItem("idioma", idioma);
         location.href = `/set-lang/${idioma}`; 


    });

});

$(function sair(){
  $(document).ready(function() {
    $('a[href="#sair"]').click(function() {
      window.history.go(-1);
      return false;
    });
  })
});