const axios = require('axios')

module.exports.obterPoligono = function (token) {
  'use strict'
  let http = axios.create({ baseURL: `https://api-mapas-service.smgeo.com.br` })
  let response = http.get(`/geojson/generate_by_token?token=${token}&status=1`)
  return response
}

module.exports.obterCentro = function (gid) {
  'use strict'
  let http = axios.create({ baseURL: `https://api-mapas-service.smgeo.com.br/` })
  let response = http.get(`/geojson/get_center?table=perimetros_unificados&gid=${gid}`)
  return response
}
