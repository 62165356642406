const $ = require('jquery')
const { obterPoligono, obterCentro } = require('./requests')
require('./translate')

function initMap () {
  obterPoligono($('#token').val()).then(poligono => {
    poligono = JSON.parse(poligono.data)

    let gid = poligono.features[0].properties.gid

    obterCentro(gid).then(result => {
      JSON.parse(result.data)

      let geometry = JSON.parse(poligono.features[0].geometry)
      let coordenadas = geometry.coordinates[0][0]
      const oligopolycoordinates = coordenadas.map(latLng => ({ lng: latLng[0], lat: latLng[1] }))

      let map = new google.maps.Map(document.getElementById('map'), {
        zoom: 13,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: 'satellite'
      })

      var bounds = new google.maps.LatLngBounds()

      for (let index = 0; index < coordenadas.length; index++) {
        var coordenadas_ = coordenadas[index]

        var myLatLng = new google.maps.LatLng(coordenadas_[1], coordenadas_[0])

        oligopolycoordinates.push({ lat: coordenadas_[1], lng: coordenadas_[0] })

        bounds.extend(myLatLng)
      }

      map.fitBounds(bounds)

      let status = $('#status').val()
      let stylePoligono = {
        paths: oligopolycoordinates,
        strokeColor: '#afaa1d',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        fillColor: '#f1d405',
        fillOpacity: 0.15
      }

      if (status === 3) {
        stylePoligono = {
          paths: oligopolycoordinates,
          strokeColor: '#f44336',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#a21d13',
          fillOpacity: 0.15
        }
      }

      let propriedade = new google.maps.Polygon(stylePoligono)
      propriedade.setMap(map)
    })
  })
}

$(() => initMap())
